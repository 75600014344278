.vinbutton {
	position: relative;
	height: 50px;
	width: 280px;
	border: 2px solid var(--primary-color);
	border-radius: 5px;
	text-transform: uppercase;
	overflow: hidden;
	box-shadow: 0 4px 12px 0 rgba(152, 160, 180, 10);
	z-index: 1;
  	border-radius: 30px;
	cursor: pointer;
	background: none;
}

.vinbutton:hover {
	background: #520ca7;
}

.vinbutton:hover .buttonText {
	color: white;
}


.buttonText {
    position: relative;
    z-index: 2;  /* Ensure this is higher than fillOne's z-index */
	color: var(--primary-color);
	font-size: 1.1em;
	font-weight: 500;
}