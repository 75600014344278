

.cardContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 12px;
    padding: 20px;
    cursor: pointer;
}


.cardContainerDoppelt {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 12px;
    padding: 20px;
    cursor: pointer;
}


.cardImg {
   max-width: 200px;
   width: auto;
   object-fit: contain;
   aspect-ratio: 200/150;
   padding: 5px 10px;
  
}
.cardImgDoppelt {
    max-width: 200px;
    text-align: left;
    align-items: flex-start;
    /*aspect-ratio: 200/150;*/
    padding: 5px 10px;
}

.cardTitle {
    margin: 0;
    color: var(--primary-color);
}

/* Card Title für Partner Liste */
.cardTitleSmall {
    text-align: center;
    color: var(--primary-color);
}

.cardTitleDoppeltContainer {
    margin: 0px 0px 0px 20px;
}


.shake {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;
  
    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
  }
  
  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }

@media screen and (max-width:762px) {

    .cardContainer {
        margin: 20px 10px;
        padding: 0px;
        /*background: red;*/
    }

    .cardImg {
        max-width: 120px;
        width: auto;
        object-fit: contain;
        aspect-ratio: 200/150;
        padding: 5px 10px;
    }
    

    .cardTitle {
        text-align: center;
        font-size: 95%;
    }

    /* Card Title für Partner Liste */
    .cardTitleSmall {
        text-align: center;
        color: var(--primary-color);
        font-size: 85%;
    }

    .cardTitleDoppeltContainer {
        margin: 0;
    }

    .cardContainerDoppelt {
        margin: 10px 10px;
        /*background: green;*/
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border-radius: 12px;
        padding: 20px;
        cursor: pointer;
    }

    .cardImgDoppelt {
        max-width: 150px;
        text-align: left;
        align-items: flex-start;
        /*aspect-ratio: 200/150;*/
        padding: 5px 10px;
    }

}

