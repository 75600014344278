.vinbutton {
	position: relative;
	height: 50px;
	width: 280px;
	border: 0;
	border-radius: 5px;
	text-transform: uppercase;
	overflow: hidden;
	box-shadow: 0 4px 12px 0 rgba(152, 160, 180, 10);
	z-index: 1;
  	border-radius: 30px;
	cursor: pointer;
}


.fillOne {
	position: absolute;
	background-image: linear-gradient(to right, #a020F0, #650ca7);
	height: 70px;
	width: 420px;
	border-radius: 5px;
	margin: -40px 0 0 -140px;
	z-index: 0;
	transition: all 0.4s ease;
	cursor: pointer;
}

.containerOne:hover .fillOne {
	-webkit-transform: translateX(100px);
	-moz-transform: translateX(100px);
	transform: translateX(100px);
	cursor: pointer;
}

.buttonText {
    position: relative;
    z-index: 2;  /* Ensure this is higher than fillOne's z-index */
	color: white;
	font-size: 1.1em;
	font-weight: 500;
}



@-webkit-keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@-moz-keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}