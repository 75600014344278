.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* --- Custom CSS --- */

:root {
  --primary-color: #520ca7;
}

.primCol {
  background: var(--primary-color) !important;
}

.pointer {
  cursor: pointer !important;
}

.uploadContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uploadBtn {
  width: 100%;
  border: none;
  background: var(--primary-color);
  color: white;
  font-weight: 600;
  font-size: 110%;
  text-align: center;
  border-radius: 10px;
  padding: 10px 20px;
}


.filePicker {
  color: white;
  background: #0B3499;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
}


p {
  font-size: 110%;
}

label {
  font-size: 110%;
}

/* Spacing between Buttons etc. */
.space-s {
  margin: 0px 8px;
}



/* Mobil Row etwas zu breit */
@media screen and (max-width: 762px) {
  
  /* Bootstrap row adjustment */
  .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .row > * {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  
}

/* ==================== Footer Seitenende ==================== */
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}



/* ==================== FONT IMPORTS ==================== */
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Mouly';
  src: url('./fonts/Mouly.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}










/* ==================== Signature Pad ==================== */


.sigPadContainer {
  background-color: rgb(238, 235, 235);
  padding: 0 10px 10px;
}
.sigCanvas{
  width: 100%;
  height: 200px;
  border: 2px dotted blue;
  border-radius: 8px;
}

.sigPadContainer hr{
  margin-bottom: unset;
  background-color: gainsboro;
}

.sigPadContainer button {
  border: none;
  margin-left: auto;
  color: rgb(78, 75, 75);
  padding: 0;
  display: block;
  margin-top: 5px;
}

.sigPadContainer button:hover {
  background-color: unset;
  color: black;
}


.sigPad__penColors{
  margin-bottom: 10px;
}                                                                                                                  

.sigPad__penColors p{
  display: inline-block; 
  margin-right: 5px;
}

.sigPad__penColors span{
  padding: 0px 9px;
  border-radius: 100%;
  margin-right: 5px;
}


.modal__bottom .create{
  margin-left: 10px;
  background-color: rgb(126, 119, 119);
  color: white;
}

.signature {
  width: 200px;
  height: 70px;
  margin-top: 20px;
  box-shadow: 0 0 4px 2px gainsboro;
  padding: 10px;
  object-fit: contain;
}