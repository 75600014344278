.mobileApp {
  display: none; /* By default hide the mobile view */
}
.desktopApp {
  display: block; /* By default hide the mobile view */
}

.mobileAppMenu {
  display: none; /* By default hide the mobile menu */
}

@media (max-width: 768px) {

  .mobileApp {
    display: block; /* Show menu on screens smaller than or equal to 768px */
  }
  .desktopApp {
    display: none; /* Hide Desktop App */
  }

  .mobileAppMenu {
    display: block; /* Show menu on screens smaller than or equal to 768px */
  }
  body {
    padding-bottom: 5rem; /* This should match the height of your bottom navigation */
  }
}

/* Disable Input Focus Border (since Tailwind+Bootstrap...) */
input:focus {
  box-shadow: none !important;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#root {
  width: 100%;
  margin: 0;
  padding: 0;
}

h1 {
  margin-top: 30px !important;
  font-size: 24px !important;
  text-align: center;
}


/* Hide validation Message Icon Swal2 Sweetalert2 */
.swal2-validation-message::before {
  display: none !important;
}





p.active,
p.active:hover {
  position: relative; /* Add this to ensure ::after is positioned correctly */
}

p.active:after,
p.active:hover:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 10;
  content: "";
  display: block;
  height: 3px;
  left: 0; /* Change this to 0 to align with the left edge */
  position: absolute;
  background: #000;
  width: 100%; /* Change this to 100% to cover the full width */
}



