.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

@media screen and (max-width:762px) {
  .overlay {
      /* Adjust for smaller screens as needed */
  }
} 
