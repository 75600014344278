

.loginContainer {
    margin-top: 80px;
    width: 60%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cardContainer {
    display: flex;
    flex-direction: column;
    max-width: 400px !important;
    height: 100%;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 12px;
    padding: 20px;
}

.loginIMG {
    margin: 20px 0px;
    max-width: 60%;
}


.loginBtnCustom {
    background: var(--primary-color) !important;
    font-weight: bold !important;
    border-radius: 16px !important;
    padding: 20px 0px !important;
    width: 100% !important;
    font-size: 110% !important;
}

.formContainer {
    width: 90%;
}

.formContainer input {
    width: 0%;
}

.formInputCustom {
    padding: 10px 20px;
    border-radius: 8px;;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-top: 20px;
}

.formInputCustom label {
    color: var(--primary-color);
    font-weight: bold;
    font-size: 90%;
    padding-left: 12px;
}

.formInputCustom input {
    color: black;
    margin-left: 12px;
    width: 90%;
    font-size: 110%;
    border: none;
}

.formInputCustom input::placeholder {
    color: lightgrey;
}

.formInputCustom input:focus {
    outline: none;
}


.DropdownSelection {
    width: 95%;
    border: none;
    padding: 10px;
}
.DropdownSelection:focus {
    outline: none;
}



.registerContainer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}



.BtnCustom {
    margin-top: 20px !important;
    background: var(--primary-color) !important;
    font-weight: bold !important;
    border-radius: 16px !important;
    padding: 15px 20px !important;
    width: auto !important;
    font-size: 110% !important;
    border: none !important
}

.BtnCustomGreen {
    margin-top: 20px !important;
    background: rgb(83, 178, 83) !important;
    font-weight: bold !important;
    border-radius: 16px !important;
    padding: 15px 20px !important;
    width: auto !important;
    font-size: 110% !important;
    border: none !important
}


@media screen and (max-width:762px) {

    .loginContainer {
        max-width: 90%;
    }

    .formInputCustom {
        margin-top: 20px;
    }

    .footerContainer {
        margin-top: 15px !important;
    }
}