.icon {
    margin-right: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px;
}


.card {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%; 
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 12px;
    margin-bottom: 15px;
}

.cardInner {
    display: flex;
    flex-direction: row;
    align-items: center;    
    justify-content: space-between;
    width: 100%; 
    padding: 20px;
    text-align: left;
    cursor: pointer;
} 

.card p {
    margin-bottom: 0;
}

.cardContent {
   display: flex;
   align-items: center;
}



.NavDocument span {
    /* Zum Newsblog, ... Texte */
    padding-right: 10px !important;
}


.title {
    display: flex;
    flex-direction: column;
    font-size: 100%;
}

.subTitle span {
    display: inline;
    font-size: 85%;
}

.date {
    padding-right: 10px;
}

.gap {
    margin-top: 30px;
}

.cbContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 30px;
}

.resetBtn {
    width: auto;
    padding: 8px 16px !important;
    margin-bottom: 32px;
    text-align: center;
}


@media screen and (max-width:762px) {

    .NavDocument span {
       display: none;
    }

    .title{
        display: flex;
        flex-direction: column;
    }

    .date{
        display: flex;
        font-size: 80%;
    }

    .cardContent {
        padding-left: 0px;
    }

    .cbContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 20px;
        margin-bottom: 20px;
    }
    
}