
.iconheaderRow {
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.iconHeaderContainer {
    margin-top: 25px !important;
}

.iconHeaderContainer {
    cursor: pointer;
    color: #520da7 !important;
    font-size: 110%;
    text-align: right;
    width: auto;
}

.container {
    width: 100%;
}

.containerKacheln {
    width: 100%;
    max-width: 800px !important;
}

.rowContainer {
    padding: 0;
    margin: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;  /* for vertical centering */
    width: 100%;
    text-align: center;
}

.rowContainer > .col {
    justify-content: space-around !important;
}


.slogan {
    width: 100%;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 23px;
    color: grey;
    margin: 100px 0px 50px 0px;
}   

.vinlogo {
    width: 80%;
    max-width: 350px;
}

.vinding {
    width: 80%;
    max-width: 350px;
    margin-top: 60px;
    margin-bottom: 30px;
}

.boldText {
    font-weight: bold;
    text-align: center;
}

.button {
    cursor: pointer;
    border-width: 0px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 10px 20px;
    color: rgb(157, 103, 168);
    background: rgb(248, 248, 248);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    text-align: center;
 }


.cardContainer {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/*
.cardRow {
    width: 500px;
    max-width: 600px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.card {
    width: 40%;
}
*/
